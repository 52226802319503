import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  List,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as policesService from "../../../services/gestion_production/policesService";
import * as avenantsService from "../../../services/gestion_production/avenantsService";
import numeral from "numeral";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
dayjs.extend(minMax);

const validationSchema = yup.object().shape({
  start_date: yup
    .date()
    .required("La date de début de suspension est obligatoire")
    .test(
      "contrat-annuel",
      "La suspension n'est possible que pour les contrats annuels",
      function (value, context) {
        const duree =
          context.parent.souscription?.conditions_particulieres
            ?.pool_directives_tarifaires_has_insurances_durations
            ?.insurances_durations?.months_number;
        return duree === 12;
      }
    )
    .test(
      "duree-restante",
      "Il doit rester au moins 3 mois de contrat après la suspension",
      function (value, context) {
        const dateEcheance = dayjs(
          context.parent.souscription?.conditions_particulieres?.date_echeance
        );
        const dateFinSuspension = dayjs(context.parent.end_date);
        return dateEcheance.diff(dateFinSuspension, "months") >= 3;
      }
    )
    .test(
      "limite-suspension",
      "La suspension n'est plus possible après 9 mois de contrat",
      function (value, context) {
        const dateEffet = dayjs(
          context.parent.souscription?.conditions_particulieres?.date_effet
        );
        return dayjs().diff(dateEffet, "months") <= 9;
      }
    ),
  end_date: yup
    .date()
    .required("La date de fin de suspension est obligatoire")
    .test(
      "duree-suspension",
      "La durée de suspension ne peut pas dépasser 9 mois",
      function (value, context) {
        const dateDebut = dayjs(context.parent.start_date);
        const dateFin = dayjs(value);
        return dateFin.diff(dateDebut, "months") <= 9;
      }
    ),

  amount: yup
    .number()
    .test(
      "is-required-if-ristourne",
      "Le montant de la ristourne est obligatoire et doit être supérieur à 5000 FCFA",
      function (value) {
        const { suspension_type } = this.parent;
        // Si `suspension_type` est "ristourne", alors `amount` est requis et doit être >= 5000
        if (suspension_type === "ristourne") {
          return value !== undefined && value >= 5000;
        }
        // Sinon, `amount` peut être facultatif
        return true;
      }
    ),
  report_date: yup
    .date()
    .test(
      "is-required-if-report_echeance",
      "La date de report de l'échéance est obligatoire",
      function (value) {
        const { suspension_type } = this.parent;
        // Si `suspension_type` est "report_echeance", alors `report_date` est requis
        if (suspension_type === "report_echeance") {
          return value !== undefined && value !== null;
        }
        // Sinon, `report_date` peut être facultatif
        return true;
      }
    ),
  suspension_type: yup
    .string()
    .oneOf(["ristourne", "report_echeance", "", null])
    .notRequired(),
});

function AvenantSuspensionModal({
  callback = () => {},
  open = false,
  setOpen,
  souscription_uuid,
  onSuccessCallBack = () => {},
}) {
  const [souscription, setSouscription] = useState(null);

  const formik = useFormik({
    initialValues: {
      amount: 0,
      report_date: "",
      suspension_type: "",
      start_date: "",
      end_date: "",
      souscription: null,
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        start_date: values.start_date,
        end_date: values.end_date,
        suspension_type: values.suspension_type,
        ...(values.suspension_type === "ristourne" && {
          amount: values.amount,
        }),
        ...(values.suspension_type === "report_echeance" && {
          report_date: values.report_date,
        }),
      };
      avenantsService
        .createAvenantSuspension(souscription_uuid, data)
        .then((response) => {
          setSubmitting(false);
          onSuccessCallBack();
          toast("Suspension effectuée avec succès!", {
            type: "success",
            title: "Succès",
          });
          setOpen(false);
        })
        .catch((error) => {
          toast(
            `${
              error.response.data ||
              "Une erreur s'est produite pendant l'opération"
            }`,
            {
              type: "error",
              title: "Erreur",
            }
          );
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleReset,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;

  const fetchSouscription = async () => {
    try {
      const response = await policesService.findOne(souscription_uuid);
      setSouscription(response.data);
    } catch (error) {}
  };

  const calculateRistourne = (startDate, endDate, rcPrime) => {
    const suspensionDays = dayjs(endDate).diff(startDate, "days");
    const dailyRcPrime = rcPrime / 365; // prime RC journalière
    return Math.round(dailyRcPrime * suspensionDays * 0.75); // 3/4 de la prime RC pour la période
  };

  const calculateNewEcheance = (startDate, endDate, currentEcheance) => {
    const suspensionDays = dayjs(endDate).diff(startDate, "days");
    const reportDays = Math.floor(suspensionDays * 0.75); // 3/4 de la durée de suspension
    return dayjs(currentEcheance).add(reportDays, "days").format("YYYY-MM-DD");
  };

  useEffect(() => {
    setFieldValue("souscription", souscription);
  }, [souscription]);

  useEffect(() => {
    if (souscription_uuid && open === true) {
      fetchSouscription();
    }
  }, [souscription_uuid, open]);

  useEffect(() => {
    if (values.start_date && values.end_date) {
      if (values.suspension_type === "ristourne") {
        setFieldValue(
          "amount",
          calculateRistourne(
            values.start_date,
            values.end_date,
            Number(souscription?.prime_nette)
          )
        );
      } else if (values.suspension_type === "report_echeance") {
        setFieldValue(
          "report_date",
          calculateNewEcheance(
            values.start_date,
            values.end_date,
            dayjs(souscription?.conditions_particulieres?.date_echeance)
          )
        );
      }
    } else {
      setFieldValue("suspension_type", "");
      setFieldValue("amount", 0);
      setFieldValue("report_date", "");
    }
  }, [values.suspension_type, values.end_date, values.start_date]);

  return (
    <Modal
      onClose={() => {
        typeof open === "boolean" ? setOpen(false) : setOpen(null);
      }}
      onOpen={() => {
        typeof open === "boolean" ? setOpen(true) : setOpen("retrait-garantie");
      }}
      open={typeof open === "string" ? open === "retrait-garantie" : open}
      closeIcon
      onUnmount={() => {
        if (open !== true) {
          handleReset();
        }
      }}
      size="large"
    >
      <FormikProvider value={formik}>
        <Modal.Header>Avenant de suspension</Modal.Header>
        <Modal.Content>
          <Modal.Description className="ui tiny form">
            <Grid divided>
              <Grid.Row>
                <Grid.Column width={6}>
                  {souscription && (
                    <>
                      <Segment raised>
                        <Header as="h5">Informations de la souscription</Header>
                        <List>
                          <List.Item>
                            <List.Icon name="calendar alternate" />
                            <List.Content>
                              <List.Header>Date de prise d'effet</List.Header>
                              <List.Description>
                                {dayjs(
                                  souscription?.conditions_particulieres
                                    ?.date_effet
                                ).format("DD-MM-YYYY")}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Icon name="calendar alternate" />
                            <List.Content>
                              <List.Header>Date d'échéance</List.Header>
                              <List.Description>
                                {dayjs(
                                  souscription?.conditions_particulieres
                                    ?.date_effet
                                )
                                  .add(
                                    Number(
                                      souscription?.conditions_particulieres
                                        ?.pool_directives_tarifaires_has_insurances_durations
                                        ?.insurances_durations?.months_number
                                    ),
                                    "months"
                                  )
                                  .add(-1, "day")
                                  .format("DD-MM-YYYY")}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Icon name="calendar alternate" />
                            <List.Content>
                              <List.Header>Jours restants</List.Header>
                              <List.Description>
                                {dayjs(
                                  souscription?.conditions_particulieres
                                    ?.date_echeance
                                ).diff(
                                  dayjs.max(
                                    dayjs(),
                                    dayjs(
                                      souscription?.conditions_particulieres
                                        ?.date_effet
                                    )
                                  ),
                                  "days"
                                )}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Segment>
                      <Segment raised>
                        <Form.Field required error={!!errors.start_date}>
                          <label>Date de début de suspension</label>
                          <Input
                            type="date"
                            value={values.start_date}
                            max={dayjs(
                              souscription?.conditions_particulieres
                                ?.date_echeance
                            ).format("YYYY-MM-DD")}
                            min={dayjs(
                              souscription?.conditions_particulieres?.date_effet
                            ).format("YYYY-MM-DD")}
                            onChange={handleChange("start_date")}
                            onBlur={handleBlur("start_date")}
                          />
                          <small className="field-error">
                            {errors.start_date}
                          </small>
                        </Form.Field>
                        <Form.Field required error={!!errors.end_date}>
                          <label>Date de fin de suspension</label>
                          <Input
                            type="date"
                            value={values.end_date}
                            max={dayjs(
                              souscription?.conditions_particulieres
                                ?.date_echeance
                            ).format("YYYY-MM-DD")}
                            min={dayjs(values.start_date).format("YYYY-MM-DD")}
                            onChange={handleChange("end_date")}
                            onBlur={handleBlur("end_date")}
                          />
                          <small className="field-error">
                            {errors.end_date}
                          </small>
                        </Form.Field>
                        <Divider />
                        <div className="items-center mt-2">
                          <Header as="h5">Jours de suspension</Header>
                          <div className="text-right">
                            {values.start_date && values.end_date ? (
                              <span className="text-gray-500">
                                {dayjs(values.end_date).diff(
                                  values.start_date,
                                  "days"
                                )}
                                {" jours, "}
                                soit{" "}
                                {dayjs(values.end_date).diff(
                                  values.start_date,
                                  "weeks"
                                )}{" "}
                                semaines
                              </span>
                            ) : (
                              <span className="text-gray-500">
                                Veuillez renseigner des dates valides
                              </span>
                            )}
                          </div>
                        </div>
                      </Segment>
                    </>
                  )}
                </Grid.Column>
                <Grid.Column width={10}>
                  <>
                    {(!values.start_date || !values.end_date) && (
                      <Segment basic>
                        <Message>
                          <Message.Header>Message</Message.Header>
                          <Message.Content>
                            Veuillez sélectionner des dates valides pour la
                            période de suspension avant de continuer.
                          </Message.Content>
                        </Message>
                      </Segment>
                    )}
                    {values.start_date && values.end_date && (
                      <>
                        {dayjs(values.end_date).diff(
                          values.start_date,
                          "weeks"
                        ) < 4 && (
                          <Segment basic>
                            <Message>
                              <Message.Header>Infos</Message.Header>
                              <Message.Content>
                                La période de suspension est inférieure à 4
                                semaines (28 jours). L'assuré ne peut donc
                                bénéficier d'une ristourne ou d'un report
                                d'échéance.
                              </Message.Content>
                            </Message>
                          </Segment>
                        )}

                        {dayjs(values.end_date).diff(
                          values.start_date,
                          "months"
                        ) > 9 && (
                          <Segment basic>
                            <Message>
                              <Message.Header>Infos</Message.Header>
                              <Message.Content>
                                La période de suspension est supérieure à 9
                                mois. L'assuré ne peut donc bénéficier d'une
                                ristourne ou d'un report d'échéance. De plus, le
                                contrat sera resillié et la prime sera acquise à
                                l'assureur.
                              </Message.Content>
                            </Message>
                          </Segment>
                        )}

                        {dayjs(values.end_date).diff(
                          values.start_date,
                          "weeks"
                        ) >= 4 &&
                          dayjs(values.end_date).diff(
                            values.start_date,
                            "months"
                          ) <= 9 &&
                          dayjs(
                            souscription?.conditions_particulieres
                              ?.date_echeance
                          ).diff(values.end_date, "months") >= 3 && (
                            <>
                              <Segment>
                                <Header dividing as="h5">
                                  Que souhaite l'assuré pour la suspension?
                                </Header>
                                <FormField>
                                  <Checkbox
                                    toggle
                                    label="Bénéficier d'une ristourne"
                                    checked={
                                      values.suspension_type === "ristourne"
                                    }
                                    onChange={() => {
                                      setFieldValue(
                                        "suspension_type",
                                        "ristourne"
                                      );
                                      setFieldValue(
                                        "amount",
                                        calculateRistourne(
                                          values.start_date,
                                          values.end_date,
                                          Number(souscription?.prime_nette)
                                        )
                                      );
                                    }}
                                  />
                                </FormField>
                                <FormField>
                                  <Checkbox
                                    toggle
                                    label="Report de l'échéance"
                                    checked={
                                      values.suspension_type ===
                                      "report_echeance"
                                    }
                                    onChange={() => {
                                      setFieldValue(
                                        "suspension_type",
                                        "report_echeance"
                                      );
                                    }}
                                  />
                                </FormField>
                              </Segment>
                              {values.suspension_type && (
                                <Segment raised>
                                  {values.suspension_type === "ristourne" && (
                                    <>
                                      <div>
                                        <Header dividing as="h5">
                                          Prime nette RC
                                        </Header>
                                        <div className="text-right">
                                          <span className="text-muted font-extrabold text-xl ">
                                            {numeral(
                                              Number(souscription?.prime_nette)
                                            ).format("")}{" "}
                                            FCFA
                                          </span>
                                        </div>
                                      </div>
                                      <div>
                                        <Header dividing as="h5">
                                          Montant de la ristourne (3/4 de la
                                          prime nette RC)
                                        </Header>
                                        <div className="text-right">
                                          <span className="text-muted font-extrabold text-xl ">
                                            {numeral(values.amount).format("")}{" "}
                                            FCFA
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {values.suspension_type ===
                                    "report_echeance" && (
                                    <>
                                      <Header dividing as="h5">
                                        Date de report de l'échéance (3/4 de la
                                        durée de suspension)
                                      </Header>
                                      <Input
                                        type="date"
                                        readOnly
                                        className="w-[200px]"
                                        value={calculateNewEcheance(
                                          values.start_date,
                                          values.end_date,
                                          dayjs(
                                            souscription
                                              ?.conditions_particulieres
                                              ?.date_echeance
                                          )
                                        )}
                                        onChange={handleChange("report_date")}
                                        onBlur={handleBlur("report_date")}
                                      />
                                    </>
                                  )}
                                </Segment>
                              )}
                            </>
                          )}
                      </>
                    )}
                  </>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Fermer
          </Button>
          <Button
            content="Enregistrer"
            labelPosition="right"
            icon="save"
            onClick={handleSubmit}
            positive
            loading={isSubmitting}
            type="submit"
            disabled={
              !values.start_date ||
              !values.end_date ||
              dayjs(values.start_date).isBefore(
                souscription?.conditions_particulieres?.date_effet
              )
            }
          />
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  );
}

export default AvenantSuspensionModal;
